//{ImageSharp.fields__data__type}.js
import React from "react";
import Layout from '../../../../components/Layout';
import { Section } from '../../../../components/Section';
import { graphql } from 'gatsby';
import Seo from "../../../../components/Seo";
import Img from "gatsby-image";

class ImagePage extends React.Component {

    constructor(props) {
      super();
    }
    render() {
        let hasInfo = false;
        const imageNode = this.props.data.allImageSharp.edges[0].node;
        hasInfo = (
            imageNode.fields.data.shortName ||
            (imageNode.fields.data.date && (imageNode.fields.data.shortName || imageNode.fields.data.locationName)) ||
            imageNode.fields.data.locationName ||
            imageNode.fields.data.description
        );
        let crumbs = [];
        crumbs.push([imageNode.fields.data.typeSlug,imageNode.fields.data.typeName]);
        if(imageNode.fields.data.categoryGroup !== "hidden") crumbs.push([imageNode.fields.data.categoryGroupSlug,imageNode.fields.data.categoryGroupName])
        crumbs.push([imageNode.fields.data.categorySlug,imageNode.fields.data.categoryName]);
        //console.log(JSON.stringify(this.props.data.allImageSharp.edges[0],null,4));

        let seoTitleSuffix = false;
        if(imageNode.fields.data.shortName) {
            seoTitleSuffix = imageNode.fields.data.shortName;
        } else  if(imageNode.fields.data.locationName) {
            seoTitleSuffix = imageNode.fields.data.locationName;
        }


        return (
            <Layout location={this.props.location}>
                <Seo pathname={this.props.path} image={imageNode.fluid.src} aditionalKeywords={seoTitleSuffix + (imageNode.fields.data.date ? ", " + imageNode.fields.data.date : "")} context={{type:imageNode.fields.data.type, categoryGroup: imageNode.fields.data.categoryGroup, category: imageNode.fields.data.category, imagePageShortName: seoTitleSuffix}} />
                <div style={{paddingTop:"50px"}}>
                    <Section crumbs={crumbs} useH2={imageNode.fields.data.shortName?true:false} />
                    <div style={{margin: "10px"}}>
                        <Img fluid={imageNode.fluid} className={"imagePageImageWrapper"}/>
                        {hasInfo &&
                            <div className={"imagePageInfo"}>
                                <span>
                                    <div className={"imagePageInfoDateContainer"}>{(imageNode.fields.data.date) ? <span className={"imagePageInfoDate"}>{imageNode.fields.data.date}<br /></span> : null }</div>
                                    {(imageNode.fields.data.shortName) ? <h1><span className={"imagePageInfoName"}>{imageNode.fields.data.shortName}<br /></span></h1> : null }
                                    {(imageNode.fields.data.locationName) ? <h2><span className={"imagePageInfoLocation"}>{imageNode.fields.data.locationName}<br /></span></h2> : null }
                                    {(imageNode.fields.data.description) ? <span className={"boxInfoDescription"}>{imageNode.fields.data.description}<br /></span> : null }
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </Layout>
        )
    }
}

export default ImagePage;

export const pageQuery = graphql`
  query imagePageData ($fields__data__imageFileName: String, $fields__data__type: String, $fields__data__categoryGroup: String, $fields__data__category: String){
    allImageSharp(filter: {fields: {data: {sourceInstanceName: {eq: "pictures"}, type: {eq: $fields__data__type}, categoryGroup: {eq: $fields__data__categoryGroup}, category: {eq: $fields__data__category}, imageFileName: {eq: $fields__data__imageFileName}}}}) {
      edges {
        node {
          fluid(quality: 80, maxWidth: 1080) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
            base64
            aspectRatio
            src
            srcSet
            srcSetWebp
            sizes
            originalImg
            originalName
          }
          original {
            width
            height
          }
          fields{
            data{
              type
              typeName
              typeSlug
              categoryGroup
              categoryGroupName
              categoryGroupSlug
              category
              categoryName
              categorySlug
              shortName
              description
              date
              locationName
              locationLink
            }
          }  
        }
      }
    }
  }
`;